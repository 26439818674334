import { Injectable } from '@angular/core';
import {
  Filesystem,
  Directory,
  Encoding,
} from '@capacitor/filesystem';

@Injectable({
  providedIn: 'root'
})




export class fileClass {
  // is different - use the useFuction for settings
  dirpath   = '';

  constructor(){


  }



  setDebugMode(active){
    if(parseFloat(active) > 0) {
      localStorage.setItem('APPDEBUG', '1');
    }
    else{
      localStorage.removeItem('APPDEBUG');
    }
  }

  getDebugMode(){
    var t = localStorage.getItem('APPDEBUG')|| 0;
    if(t){
      return 1;
    }
    else{
      return 0;
    }
  }


  async writeDatafile(filepath,datas){
    return  await Filesystem.writeFile({
      path: filepath,
      data: datas,
      directory: Directory.Documents,
      encoding: Encoding.UTF8,
    });
  }

  async readDatafile(filepath){
    return await Filesystem.readFile({
      path: filepath,
      directory: Directory.Documents,
      encoding: Encoding.UTF8,

    }).catch(r=>{
      return r;
    });
  }


  async writeFile(filename,datas){
    return  await Filesystem.writeFile({
      path: this.dirpath+filename,
      data: datas,
      directory: Directory.Documents,
       encoding: Encoding.UTF8,
    });
  }



  async readFile(filename){
    return await Filesystem.readFile({
      path: this.dirpath+filename,
      directory: Directory.Documents,
      encoding: Encoding.UTF8,

    }).catch(r=>{
      return r;
    });
  }

  async deleteFile(filename)   {
    return await Filesystem.deleteFile({
      path: this.dirpath+filename,
      directory: Directory.Documents,
    });
  };

  async isFile(filename){
     this.readDatafile(filename).then(d => {
      if(d['data'] !== undefined) {
        return true;
      }
      else{
        return false;
      }
    })
  }


  async useAbnahmen(){
    this.dirpath = 'abnahmen';
    this.createMainDir(this.dirpath).then(b=>{
      this.dirpath+='/';
    });
  }

 async useObjekte(){
    this.dirpath = 'objekte';
    this.createMainDir(this.dirpath).then(b=>{
      this.dirpath+='/';
    });
  }

  public async useReparaturen(){
    this.dirpath = 'reparaturen';
    this.createMainDir(this.dirpath).then(b=>{
      this.dirpath+='/';
    });

  }

  async appendToFile(filename,datas){
    return  await Filesystem.writeFile({
      path: this.dirpath+filename,
      data: datas,
      directory: Directory.Documents,
      encoding: Encoding.UTF8,
    });
  }


 async mkdir(DIR){
   return await  Filesystem.mkdir({path:DIR,directory: Directory.Documents});
  }


  async readdir(DIR:string){
    return await  Filesystem.readdir({path:DIR,directory: Directory.Documents}).catch(e=>{
      return false;
    });
  }

  async rmdir(DIR){
    return await  Filesystem.rmdir({path:DIR,directory: Directory.Documents,recursive:true});
  }


  //////// ONLY FOR TESTs //////////////





/*  TESTFILESSYSTEM(){
   this.readdir('test').then(b=>{
     console.log('ZURÜCK',b);
     if(b){
        console.log('test existiert und wird entfernt!');
        this.rmdir('test').then(rm=>{
        console.log('IST WEG...');
          this.TESTNOW();
        });
      }
      else{
        this.TESTNOW();
      }
    }).catch(e=>{
     console.log('DAS VERZEICHNIS SCHEINT NICHT ZU EXISTIEREN.');

     //this.TESTNOW();
   });
  }*/







   async createMainDir(DIR){
    this.readdir(DIR).then(b=>{
      if(b){
        return;
      }
    }).catch(e=>{
       return this.mkdir(DIR);
    });

  }



/*  TESTNOW(){
    this.mkdir('test').then(d=>{
      console.log('test VERZEICHNIS WURDE ERSTELLT');
      this.dirpath = 'test/';
      this.writeFile('erster.txt','DAS IST DIE GESCHICHTE DER DATEI... IST SIE DABEI?').then(f=>{
        console.log('DATEI erter.txt erstellt...',f);
        this.readFile('erster.txt').then(fi=>{
          console.log('INHALT IST:',fi);
          this.readdir('test').then(b=>{
            console.log('READ DIR: ',b);
          });
        });
      });
    });
  }*/
}
