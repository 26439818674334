import {
  Injectable
} from "@angular/core";

import {
  isArray
} from "rxjs/internal-compatibility";
import {
  ToastController
} from '@ionic/angular';
import {
  AppDataClass
} from "./appDataClass";

@Injectable({
  providedIn: 'root'
})


export class ProtokollFunction {

  DEBUGMODE: boolean = false;
  obj_id: any = '';
  prozessart = '';

  DS: any = [];
  NONSHOW = [];
  PROCESS = {};
  abnahmeAttribute: any = {};
  FELDER = [];
  RESULTS: any = {};
  IMAGES: any = {};


  constructor(private appy: AppDataClass, public toastController: ToastController) {


  }


  buildField(field) {

    if (this.FELDER[field.id] !== undefined) {
      var orgField = this.FELDER[field.id];

      var gin = false;
      if (orgField.feldgruppenindex != '') {
        gin = true;
      }

      var is = false;
      if (parseFloat(orgField.required || 0) < 1) {
        is = true;
      }


      var r = parseFloat(orgField.required || 0);
      if (parseFloat(orgField.feldart) === 10) {
        r = 0;
      }

      var newField = {
        'id': field.id,
        'fid': field.fid,
        'name': orgField.name,
        'bemerkung': parseFloat(orgField.bemerkung || 0),
        'bemerkung_txt': '',
        'image': parseFloat(orgField.image || 0),
        'value': '',
        'value_txt': '',
        'feldart': parseFloat(orgField.feldart),
        'isalternative': orgField.isalternative,
        'feldgruppenindex': gin,
        'auswahlfelder': (orgField.auswahlfelder || false),
        'auswahlindex': orgField.auswahlindex,
        'required': parseFloat(orgField.required || 0),
        'active': '',
        'ischanged': is,
        'subfield': (orgField.subfield || false),
      };
      if (parseFloat(orgField.feldart) === 1) {

        if (orgField.feldart['meta'] !== undefined) {
          newField['label_true'] = orgField.feldart['meta']['label'];
          newField['label_false'] = orgField.feldart['meta']['labelno'];

        } else {
          newField['label_true'] = 'Ja';
          newField['label_false'] = 'Nein';
        }
        newField['sub'] = false;
        newField['alternative'] = false;

      }

      if (orgField.gruppenslug == 'vereinbarungen') {
        newField['dynart'] = 1;
      }

      if (parseFloat(orgField.feldart) === 10) {
        newField['dynart'] = 1;
        if (orgField.gruppenslug == 'vereinbarungen') {
          newField['dynart'] = 2;
        }
        newField['dynamics'] = [];
      }


      if (parseFloat(orgField.feldart) === 2) {
        newField['zaehler'] = [];
      }


      return newField;
    }
  }


  async getTabStatus(TABS, abnahmeart) {

    var SAVETABS   = {};
    var REPARATUR  = [];
    var openfelder = [];

    var statusinfo  = {};

    var countFelder: number = 0;
    var countDone:   number = 0;
    var countRequired: number = 0;
    var testZahl = 0;

    var blockIDbyUebergabe = 32; /// Hinweise
    if (abnahmeart !== 'Übergabe') {


    }

    for (let key in TABS) {
      var TAB = TABS[key];
      // ERSTMAL SAVETAB SETZEN - ERSTER IST DA...
      SAVETABS[key] = {};
      statusinfo[key] = {};
      if (TAB['datensatz'] !== undefined) {
        /// DS IN TABS

        var SAVEDS = [];
        for (let dskey in TAB['datensatz']) {
          var DS = TAB['datensatz'][dskey];
          statusinfo[key][dskey] = {'menge':0,'done':0,'fid':[]};
          /// WENN EINER FEHLT - DANN AUF 1 SETZEN !!!



          if (abnahmeart !== 'Übergabe' && DS.ds_id == blockIDbyUebergabe) {
            DS.active = false;
          }

          if (DS.active != undefined && DS.active) {

            var DS_SATZ = {
              'name': DS['label'],
              'tab_id': key,
              'fid': DS['fid'],
              'maingruppen': [],
            };

            if (DS.maingruppen !== undefined) {

              for (let mkey in DS.maingruppen) {

                let MAINGRUPPEN = DS.maingruppen[mkey];

                for (let mgkey in MAINGRUPPEN) {
                  let MAINGRUPPE = MAINGRUPPEN[mgkey];
                  let SAVEMG = {
                    'name': (MAINGRUPPE['label'] || ''),
                    'ds_id': MAINGRUPPE.ds_id,
                    'id': MAINGRUPPE.id,
                    'gruppen': [],

                  };



                  if (MAINGRUPPE['gruppen'] !== undefined) {
                    for (let grKey in MAINGRUPPE['gruppen']) {
                      let GR = MAINGRUPPE['gruppen'][grKey];
                      let grToList = true;

                      let FELDER = [];
                      let OF = GR['felder'];


                      let REQ = (GR.required || GR.isindex);
                      let COUNT = false;
                      let ISOK  = false;

                      if (GR.feldart > 0 && REQ) {
                        statusinfo[key][dskey].menge++;

                        countRequired++;
                        COUNT = true;

                        if ((GR.ischanged || GR.value_txt != '')) {
                          countDone++;
                          ISOK = true;
                          statusinfo[key][dskey].done++;

                          /// NUN ERMITTELN - WELCHE FELDER WIR NUN NUTZEN ////
                          if (GR.feldart === 1) {


                            if (GR.active) {
                              FELDER = OF['felder'] || [];
                            } else {
                              FELDER = OF['alternative'] || [];
                              if (OF['alternative'] == undefined) {
                                grToList = false;
                              }


                            }

                          }
                          if (GR.feldart === 4) {
                            if (GR.value < 1 && GR.value_txt !== 'In Ordnung' ) {
                              FELDER = OF['alternative'] || [];
                              if (OF['alternative'] == undefined) {
                                grToList = false;
                              }
                            } else {
                              FELDER = OF['felder'] || [];
                            }
                          }
                        }
                        else{
                          /// FID - WELCHES FELD NOCH FEHLT !!!!
                          statusinfo[key][dskey]['fid'].push(GR.fid);
                        }


                      } else {
                        /// IST NUR HEADLINE - FELDER SIND VORHANDEN
                        FELDER = OF['felder'] || [];

                      }


                      //// NUN EACHEN WIR DIE FELDER //// /

                      let newFelder = [];

                      for (let fk in FELDER) {
                        let FE1 = FELDER[fk];
                        let CountF = false;
                        let isCkF = false;

                        let toList = true;

                        let SUBS;
                        let ORGSUBS = FE1['sub'] || [];

                        if ((FE1.required || FE1.feldgruppenindex) && FE1.feldart !== 10) {
                          countRequired++;
                          statusinfo[key][dskey].menge++;
                          CountF = true;


                          if (FE1.ischanged || FE1.value_txt != '') {
                            countDone++;
                            statusinfo[key][dskey].done++;
                            isCkF = true;

                            /// SUBFELDER PRO SEITE ERMITTELN ///
                            if (FE1.feldart == 1) {

                              if (FE1.active) {
                                SUBS = ORGSUBS['felder'] || [];
                              } else {

                                SUBS = ORGSUBS['alternative'] || [];
                                if (ORGSUBS['alternative'] == undefined) {
                                  toList = false;
                                }
                              }
                            }

                            if (FE1.feldart == 4) {
                              if (FE1.value < 1 && FE1.value_txt !== 'In Ordnung') {
                                SUBS = ORGSUBS['alternative'] || [];
                                if (ORGSUBS['alternative'] == undefined) {
                                  toList = true;
                                }
                              } else {
                                toList = true;
                                SUBS = ORGSUBS['felder'] || [];
                              }
                            }

                            if (FE1.feldart == 2) {
                              if (FE1.value < 1 && FE1.value_txt !== 'In Ordnung') {
                                SUBS = ORGSUBS['alternative'] || [];
                                if (ORGSUBS['alternative'] == undefined) {
                                  toList = false;
                                }
                              } else {
                                SUBS = ORGSUBS['felder'] || [];
                               if(FE1.zaehler[0] !== undefined) {
                                 countRequired++;
                                 statusinfo[key][dskey]['menge']++;
                                 if (FE1.zaehler[0]['nr'] !== '' && (FE1.zaehler[0]['stand'] !== '')) {
                                   countDone++;
                                   statusinfo[key][dskey]['done']++;
                                 }
                                 else{
                                   statusinfo[key][dskey]['fid'].push(FE1.fid);
                                 }
                               }
                              }
                            }
                            if (FE1.feldart == 10) {
                              if (!FE1.dynamics && FE1.bemerkung_txt == '') {
                                toList = false;
                              }
                            }
                          }
                          else{
                              // FID - WELCHES FELD NOCH FEHLT !!!!
                              statusinfo[key][dskey]['fid'].push(FE1.fid);
                          }
                        }

                        let newSubs = [];
                        if (SUBS) {
                          for (let sk in SUBS) {
                            let SF = SUBS[sk];
                            let CountS = false;
                            let isOkS = false;
                            let toSFlist = true;

                            if (SF.required) {
                              countRequired++;
                              statusinfo[key][dskey].menge++;
                              CountS = true;

                              if (SF.ischanged || SF.value_txt !== '') {
                                countDone++;
                                statusinfo[key][dskey].done++;
                                isOkS = true;

                              }
                              else{
                                statusinfo[key][dskey].fid.push(SF.fid);
                              }


                              if (SF.feldart == 2) {
                                if (SF.value < 1 && SF.value_txt !== 'In Ordnung') {
                                  toSFlist = false;
                                }
                              }

                              if (SF.feldart == 4) {
                                if (SF.value < 1 && SF.value_txt !== 'In Ordnung') {
                                  toSFlist = false;
                                }
                              }
                              if (SF.feldart == 1) {
                                if (!SF.active) {
                                  toSFlist = false;
                                }

                              }

                              if (SF.feldart == 10) {

                                if (!SF.dynamics && SF.bemerkung_txt == '') {
                                  toSFlist = false;
                                }

                              }


                            }


                            /// FELDER 2. EBENE ////
                            if (toSFlist) {
                              let sfeld = {

                                'name': (SF['name'] || ''),
                                'value_txt': (SF['value_txt'] || ''),
                                'value': (SF['value'] || ''),
                                'active': (SF['active'] || ''),
                                'bemerkung_txt': SF.bemerkung_txt,
                                'ds_id': SF.ds_id,
                                'fid': SF.fid,
                                'id': SF.id,
                                'feldart': SF.feldart,
                                'required': (SF.required || SF.isindex),
                                'ischanged': (SF.ischanged || false),
                                'tocount': CountS,
                                'isOk': isOkS,
                              }

                              if (SF.feldart === 10) {
                                sfeld['dynamics'] = SF.dynamics || [];


                              }
                              if (SF.feldart === 2) {
                                sfeld['zaehler'] = SF.zaehler || [];
                              }


                              if (!isOkS) {
                                var o = {
                                  'name': (SF['name'] || ''),
                                  'value_txt': (SF['value_txt'] || ''),
                                  'feldart': SF.feldart,
                                  'fid': SF.fid,
                                  'feldbereich': 'SUBFELD',
                                }
                                openfelder.push(o);
                              }
                              /// SUBFELD HINZUFÜGEN ////
                              newSubs.push(sfeld);
                            }
                          }

                        }


                        var fbem = FE1.bemerkung_txt;
                        if (FE1.bemerkung_txt && FE1.feldart == 1 && !FE1.active) {
                          fbem = '';
                        }

                        if (toList && FE1['value_txt'] !== 'Nicht vorhanden') {

                          /// FELDER ERSTE EBENE //////
                          let feld = {

                            'name': (FE1['name'] || ''),
                            'value_txt': (FE1['value_txt'] || ''),
                            'value': (FE1['value'] || ''),
                            'active': (FE1['active'] || ''),
                            'bemerkung_txt': fbem,
                            'ds_id': FE1.ds_id,
                            'fid': FE1.fid,
                            'id': FE1.id,
                            'feldart': FE1.feldart,
                            'required': (FE1.required || FE1.isindex),
                            'ischanged': (FE1.ischanged || false),
                            'tocount': CountF,
                            'isOk': isCkF,
                            'sub': newSubs,
                          }

                          if (FE1.feldart === 10) {
                            feld['dynamics'] = FE1.dynamics || [];
                          }

                          if (FE1.feldart === 2) {
                            feld['zaehler'] = FE1.zaehler || [];
                          }


                          if (!isCkF && CountF) {

                            var o = {
                              'name': (FE1['name'] || ''),
                              'value_txt': (FE1['value_txt'] || ''),
                              'feldart': FE1.feldart,
                              'fid': FE1.fid,
                              'feldbereich': 'Felder',
                            }
                            openfelder.push(o);
                          }


                          /// DAS FELD ZU DEN NEUEN FELDERN HINZUFÜGEN !!!!
                            newFelder.push(feld);
                        }
                      }

                      var bem = GR.bemerkung_txt;
                      if (GR.bemerkung_txt && GR.feldart == 1 && !GR.active) {
                        bem = '';
                      }


                      if (grToList) {
                        SAVEMG.gruppen.push({
                          'name':(GR['name'] || ''),
                          'value_txt': (GR['value_txt'] || ''),
                          'value': (GR['value'] || ''),
                          'active': (GR['active'] || ''),
                          'bemerkung_txt': bem,
                          'ds_id': GR.ds_id,
                          'fid': GR.fid,
                          'id': GR.id,
                          'feldart': GR.feldart,
                          'required': (GR.required || GR.isindex),
                          'ischanged': (GR.ischanged || false),
                          'tocount': COUNT,
                          'isOk': ISOK,
                          'felder': newFelder,

                        });


                        if (!ISOK && COUNT) {
                          var o = {
                            'name': (GR['name'] || ''),
                            'value_txt': (GR['value_txt'] || ''),
                            'feldart': GR.feldart,
                            'fid': GR.fid,
                            'feldbereich': 'GRUPPE',
                          }
                          openfelder.push(o);
                        }

                      }


                    }

                  }


                  DS_SATZ.maingruppen.push(SAVEMG);

                }
              }


            }

            SAVEDS.push(DS_SATZ);
          }
            //// BIS HIER - DATENSATZ
        }

      }
      SAVETABS[key]['datensatz'] = SAVEDS;
    }
    var p = 100 / countRequired * countDone;
    return {
      'saveTabs': SAVETABS,
      'countFelder': countFelder,
      'countProzent': p,
      'countDone': countDone,
      'countRequired': countRequired,
      'openfelder': openfelder,
      'statusinfo':statusinfo,

    }

  }



  /////// NUR ZUM BEGINN ////////

  async setObjTabs(PROTOKOLLDATAS, APPDATAS) {
    //this.appy.showLoader('Erstelle Protokoll');

    this.FELDER     = APPDATAS['felderliste'];
    let gruppen     = APPDATAS['gruppenliste'];
    let datensaetze = APPDATAS['datensatzliste'];

    let TABS        = PROTOKOLLDATAS['tabs'];
    this.obj_id     = PROTOKOLLDATAS['obj_id'];
    this.prozessart = PROTOKOLLDATAS['abnahmeart'];

    let NEWTABS = {};

    for (let key in TABS) {
      var TAB = TABS[key];

      NEWTABS[key] = {
        'name': TAB['name'],
        'id': key,
        'datensatz': []
      };

      if (TAB['datensatz'] !== undefined) {
        this.el('DATENSATZLISTE VON TAB ' + key + ' ist vorhanden');
        for (let dskey in TAB['datensatz']) {
          let TD = {};
          /// JETZT IST DER DATENSATZ ALLEIN
          var TABDS = TAB['datensatz'][dskey];
          var isIndex = TABDS.isindex;

          //// ERSTE ZUWEISUNGEN ZUM NEUEN OBJEKT
          TD['id'] = parseFloat(TABDS.id);
          TD['obj_id'] = parseFloat(this.obj_id);
          TD['tab_id'] = TABDS.tab_id;
          TD['fid'] = TABDS.fid;
          TD['ds_id'] = TABDS.ds_id;
          TD['isindex'] = isIndex;
          TD['label'] = TABDS.label;
          TD['isindex'] = false;
          TD['active'] = true;
          TD['show'] = false;
          if (isIndex != '0' || isIndex == 'true') {
            TD['isindex'] = true;
            TD['ischanged'] = false;
            if (isIndex) {

            }

          }

          if (datensaetze[TABDS.ds_id] !== undefined) {
            var DSSATZ = datensaetze[TABDS.ds_id];
            TD['beschreibung'] = DSSATZ.label;
          }
          TD['maingruppen'] = [];
          /// SCHON MAL DEFINIEREN

          if (TABDS['maingruppen'] !== undefined) {
            let mgrup = [];
            for (let mkey in TABDS['maingruppen']) {
              let MG = TABDS['maingruppen'][mkey];
              let MGS = {};
              MGS['label'] = MG['label'];
              MGS['hidelabel'] = MG['hidelabel'];
              MGS['id'] = MG['id'];
              MGS['ds_id'] = MG['ds_id'];
              MGS['tab_id'] = MG['tab_id'];
              MGS['gruppen'] = [];

              if (MG['gruppen'] !== undefined) {
                for (let gkey in MG['gruppen']) {
                  let theG = MG['gruppen'][gkey];


                  // DIE GRUPPE ALLEIN

                  let NG = {};
                  let TNG = gruppen[parseFloat(theG['gr_id'])];

                  let FA = parseFloat(theG['feldart']);

                  if (FA < 1) {
                    NG['active'] = true;
                  }
                  if (FA == 4) {
                    NG['active'] = true;
                    NG['bemerkung'] = TNG['bemerkung'] || 0;

                  }

                  NG['name'] = TNG['name'];
                  NG['label'] = TNG['indexlabel'];
                  NG['feldart'] = FA;
                  NG['isindex'] = false;
                  NG['bemerkung_txt'] = '';
                 // NG['hasImage'] = false
                  NG['bemerkung'] = TNG['bemerkung'] || 0;
                  NG['image'] = parseFloat(TNG['image'] || 0);
                  if (FA === 1) {
                    NG['label_true'] = 'Ja';
                    NG['label_false'] = 'Nein';
                    NG['value_txt'] = '';
                    NG['active'] = '';
                    NG['isindex'] = true;


                  }

                  if (FA === 4) {
                    NG['auswahlfelder'] = TNG['auswahlfelder'];

                    NG['auswahlrequired'] = TNG['auswahlrequired'];

                    NG['ischanged'] = false;
                    NG['value'] = '';
                    NG['value_txt'] = '';

                    NG['isindex'] = true;

                  }
                  NG['id'] = parseFloat(theG['id']);
                  NG['fid'] = theG['fid'];
                  NG['obj_id'] = parseFloat(theG['obj_id']);
                  NG['gr_id'] = parseFloat(theG['gr_id']);
                  NG['ds_id'] = parseFloat(theG['ds_id']);

                  var isgIndex = false;
                  if (theG['isindex']) {
                    isgIndex = true;
                  }


                  let awc = false;
                  if (TNG['auswahlrequired'] !== '') {
                    awc = true;
                  }


                  /// WIR LEGEN DIE FELDER ALS OBJEKT AN ////
                  NG['felder'] = {'felder': {}};

                  ///// WIR HOLEN UNS NUN DIE FELDER //////////
                  if (theG['felder'] !== undefined) {

                    for (let fkey in theG['felder']) {
                      /// FIRST SECTION
                      let mf = theG['felder'][fkey];

                      /// erste EBENE felder
                      if (mf['felder'] !== undefined) {
                        for (let ffkey in mf['felder']) {
                          var FELD = this.buildField(mf['felder'][ffkey]);
                          if (mf['felder'][ffkey]['sub'] !== undefined) {
                            FELD['sub'] = {'felder': {}};
                            for (let ffskey in mf['felder'][ffkey]['sub']['felder']) {
                              var sf = this.buildField(mf['felder'][ffkey]['sub']['felder'][ffskey]);
                              var sfid = ffskey;
                              FELD['sub']['felder'][sfid] = this.buildField(sf);
                            }
                            if (mf['felder'][ffkey]['sub']['alternative']) {
                              FELD['sub']['alternative'] = {};
                              for (let ffskey in mf['felder'][ffkey]['sub']['alternative']) {
                                var saf = this.buildField(mf['felder'][ffkey]['sub']['alternative'][ffskey]);
                                var safid = ffskey;
                                FELD['sub']['alternative'][safid] = this.buildField(saf);
                              }
                            }
                          }
                          NG['felder']['felder'][ffkey] = FELD;
                        }
                      }
                    }
                  }


                  ///// WIR HOLEN UNS NUN DIE FELDER //////////
                  if (theG['alternative'] !== undefined) {
                    NG['felder']['alternative'] = {};
                    for (let fkey in theG['alternative']) {
                      /// FIRST SECTION
                      let mf = theG['alternative'][fkey];

                      /// erste EBENE felder
                      if (mf['felder'] !== undefined) {
                        for (let ffkey in mf['felder']) {
                          var FELD = this.buildField(mf['felder'][ffkey]);
                          if (mf['felder'][ffkey]['sub'] !== undefined) {
                            FELD['sub'] = {'felder': {}};
                            for (let ffskey in mf['felder'][ffkey]['sub']['felder']) {
                              var sf = this.buildField(mf['felder'][ffkey]['sub']['felder'][ffskey]);
                              var sfid = ffskey;
                              FELD['sub']['felder'][sfid] = this.buildField(sf);
                            }
                            if (mf['felder'][ffkey]['sub']['alternative']) {
                              FELD['sub']['alternative'] = {};
                              for (let ffskey in mf['felder'][ffkey]['sub']['alternative']) {
                                var saf = this.buildField(mf['felder'][ffkey]['sub']['alternative'][ffskey]);
                                var safid = ffskey;
                                FELD['sub']['alternative'][safid] = this.buildField(saf);
                              }
                            }
                          }
                          NG['felder']['alternative'][ffkey] = FELD;
                        }
                      }
                    }
                  }
                  // PUSH NG TO MGS ///
                  MGS['gruppen'].push(NG);
                }
              }

              mgrup.push(MGS);
            }
            /// PUT THE GROUP TO THE ARRAY
            TD['maingruppen'].push(mgrup);

          }
          NEWTABS[key]['datensatz'].push(TD);
          /// TD['bemerkung']
        }
      } /// IF DS SATZ
    }

    return {
      'TABS': NEWTABS,
    }

    // return NEWTABS;
  }


  async presentToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      icon: 'information-circle',
      position: 'top',
      duration: 3000
    });
    return toast.present();
  }

  el(v) {

    if (this.DEBUGMODE) {
      console.log(v);
    }
  }


}
