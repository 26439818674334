import {
  Component,
  Injectable,
  OnInit,
  ViewChild
} from '@angular/core';
import {AppRoutingModule} from "../app-routing.module";
import {AuthGuardService} from "../api/auth-guard.service";
import {UserService} from "../api/user.service";
import {fileClass} from "../class/fileClass.service";
import {
  AppDate
} from "../class/dateClass";

import {
  AbnahmelisteComponent
} from "../components/abnahmeliste/abnahmeliste.component";
import {
  Router
} from "@angular/router";
import {
  AppDataClass
} from "../class/appDataClass";

@Injectable({providedIn: 'root'})

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.page.html',
  styleUrls: ['./dashboard.page.scss'],
})

@ViewChild(AbnahmelisteComponent)



export class DashboardPage implements OnInit {
  appDir = 'bewoappdatas';
  abnahmenDir = 'bewo-abnahmen';
  DEBUGMODE:any = false;
  datum       ='';
  USER:any    = {'email':'','name':'','datas':{}};
  USERFILE    =  '';
  SIGNAFILE   = '';
  DEVICEINFO:any = {};
  hasCheck = false;
  isLoadet = false;
  SHOWMOREOF = false;
  repaDir = 'reparaturen';

  API_LOGURL = '';
  API_TOKEN = '';
  constructor(private appdatas:AppDataClass,private router: Router,public liste:AbnahmelisteComponent,private file:fileClass,private userservice:UserService,private guard:AuthGuardService,private routes:AppRoutingModule) {
    var D            = new AppDate();
    this.datum       = D.getDatum();
    this.USERFILE    = this.appDir+'/userdatas.nb';
    this.DEBUGMODE   = this.getDebugMode();



    this.API_LOGURL = appdatas.loginUrl();
    this.API_TOKEN =     localStorage.getItem('bewoToken');

    this.file.readdir(this.appDir).then(b=>{
      if(!b){
        this.file.mkdir(this.appDir).then(e=>{
          this.elogger('APPDIR  ANGELEGT');
        });
      }
      this.getUserdatas().then(b=>{
        if(!b || b['error'] ===2000){
          alert('Der Login ist nicht mehr aktiv! Bitte logge dich erneut ein!');
          this.router.navigate(["/login"]).then(r => {});
        }
      });
    });
  }


  opendash(){
    // check ob Userdaten da sind ///

    this.file.readDatafile(this.USERFILE).then(a => {
        if(a['data']!=undefined){
          this.USER      = a['data'];
          this.SIGNAFILE = this.appDir+'/signatur-'+this.USER.datas.id+'.nb';
          this.liste.starter();
          this.SETSIGNATURE();
          this.SHOWMOREOF = true;
          this.isLoadet = true;
          this.elogger('USER VON FILE');
          this.elogger(this.USER);
        }
        else{
         this.LOADUSER();
          this.liste.starter();
          this.isLoadet = true;
        }


    });

  }



  logout(){
    this.file.isFile(this.USERFILE).then(b=>{
      this.file.deleteFile(this.USERFILE).then();
    });

    setTimeout(t=> {
      this.guard.logout();
    },10);
  }

  saveUser(){
    this.file.writeDatafile(this.USERFILE,this.USER).then(d=>{
     this.elogger('USERFILE GESPEICHERT '+d);
      this.elogger(this.USER);
      localStorage.setItem('user_id',this.USER.id);
      this.SIGNAFILE =  this.appDir+'/signatur-'+this.USER.id+'.nb';
      this.SETSIGNATURE();
    });
  }





  SETSIGNATURE(){
    this.file.readDatafile(this.SIGNAFILE).then(a => {
      if(a['data']!=undefined) {
        this.USER.signatur = a['data'];
      }

    });
  }


  async isTokenActive(){
    this.getUserdatas().then(b=>{
      if(b){
        return 1;
      }
      return 0;
    });
  }




  LOADUSER(){
    this.getUserdatas().then(b=>{
      if(!b){
         this.logout();
      }

      this.USER           = b.result;
      this.USER.lastlog   = '';
      this.USER.role      = this.USER.datas['user_role'];
      this.saveUser();

    }).finally(()=>{
      this.elogger('USER Geladen..');
    });
  }


  async getUserdatas(){
    return  this.userservice.loadUserDatas();

  }

  elogger(v){
    if(this.DEBUGMODE) {
     console.log(v);
    }
  }


  DEBUGINFO:any={};
  deviceCleaner(){
    var prozNr  = 0;
    var fm      = 0;
    this.DEVICEINFO = {};

    if(this.DEBUGMODE){

      this.DEBUGINFO = {
        apiurl:this.appdatas.getApiUrl(),
        token:this.API_TOKEN,
        LoginUrl:this.API_LOGURL,
        apigetter:this.appdatas.apiGetter,
        verzeichnis:this.appDir,
        abnahmedir:this.abnahmenDir,

      }

    }

    this.file.readdir(this.abnahmenDir).then(b=>{



      var FLIST = [];
      var vzlist = [];
      if(b !== undefined && b){

          let dirs = b['files']||[];
          for(let k in dirs){
            let FI = {};
            let vz = dirs[k];
            vzlist.push(this.abnahmenDir+'/'+vz);
            var FLIST = [];
            prozNr++;
            var FPath = this.abnahmenDir+'/'+vz+'/';
            // neues VZ durchsuchen nach FilEs
            this.file.readdir(FPath).then(f=>{
              FI = f['files']||'';
              for(let n in FI){
                var FPath = this.abnahmenDir+'/'+vz+'/';
                fm++;
                FLIST.push(FPath+FI[n]);
              }
            })
          }

        var RepMeng = 0;
        this.file.readdir(this.repaDir).then(b=>{
          if(b !== undefined && b) {
            let rdirs = b['files'] || [];
            for (let r in rdirs) {
              let rvz = rdirs[r];
              RepMeng++;
              vzlist.push(this.repaDir+'/'+rvz);
            }


          }
        });



        setTimeout(t=>{
          if(FLIST.length > 0){
            fm = FLIST.length;
            this.DEVICEINFO = {'dirs':vzlist,'prozesse':prozNr,'repaprozesse':RepMeng,'filemenge':fm,'datas':FLIST};
          }
        },30);

        }

    });




    setTimeout(t=>{
    this.hasCheck = true;
    },40);
  }


  /// EINFACH DEN HAUPTORDNER WEG - DANN IS AUCH ALLES SAVE !!!
  cleanerProzess(art){
    if(art === 1){
      this.file.rmdir(this.abnahmenDir).then(f => {
        this.file.isFile(this.abnahmenDir).then(t=>{
          this.file.rmdir(this.abnahmenDir).then(f => {});
        });
        this.DEVICEINFO = {};
      });
      if(this.DEBUGMODE){
        console.log('Device Cleaner hat alles erledigt!!');
      }
    }

    if(art === 2){
      this.file.rmdir(this.abnahmenDir).then(f => {
        setTimeout(t=>{
          if(this.DEBUGMODE){
            console.log('Device Cleaner hat alles erledigt!!');
          }
          this.file.rmdir(this.appDir).then(f => {
          this.logout();
        });
        },30);
      });
    }
  }



  cleanItNow(art){
    if(art === 1 && confirm('Ganz sicher? Der Cleaner sollte nur genutzt werden wenn alle Vorgänge abgeschlossen wurden. Alle Vorgänge die noch nicht im SBM gespeichert wurden werden entfernt!')) {
      this.cleanerProzess(1);
    }
    if(art === 2 && confirm('Ganz sicher? Dieser Vorgang setzt alle Daten (Vorgänge, Benutzerdaten, Benutzer Signatur etc.) zurück. Nach dem Vorgang ist ein neuer Login nötig!')) {
      this.cleanerProzess(2);
    }
  }




  setDebugMode(active){
     this.DEBUGMODE   = active;
     if(active){
       console.log('DEBUGMODE AKTIV');
     }
     else{
       console.log('DEBUGMODE WURDE DEAKTIVIERT');
     }
     this.file.setDebugMode(active);
     setTimeout(f=>{
       if(this.DEBUGMODE){
         this.deviceCleaner();
       }
     },20);
  }

  getDebugMode(){
    return this.file.getDebugMode();
  }




  ionViewWillEnter() {
    this.SHOWMOREOF = true;
    this.opendash();
    this.deviceCleaner();
    this.liste.starter();
  }


  ngOnInit() {
    //this.deviceCleaner();
   // this.liste.DirDatas().then(b=>{});

  }

}
