import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from "@angular/router";
@Injectable({
  providedIn: 'root'
})
export class AuthGuardService{
  isactivated = 0;
  token       = '';
  appTokenKey = 'bewoToken';


  getToken(){
    this.token =   localStorage.getItem(this.appTokenKey);
    return this.token;
  }


  itemCheck(){
    var token = localStorage.getItem(this.appTokenKey);
    if(token !='' && token != null){
      this.isactivated = 1;
      this.token =   localStorage.getItem(this.appTokenKey);
    }
  }



  setToken(token){
    localStorage.setItem(this.appTokenKey,token);
    this.token = token;
  }


  constructor(private router: Router) {
    this.getToken();
    this.itemCheck();
  }

 logout(){
   this.clearToken();
   this.setLevel(0);
   this.router.navigate(["/login"]).then(r => {});

 }

  clearToken(){
    localStorage.removeItem(this.appTokenKey);
    this.token = '';
  }


  setLevel(level){
    this.isactivated = level;
  }

  onInit(){

  }

  getLevel(){
    return this.isactivated;
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    let authInfo = {
      authenticated: this.isactivated
    };

    if (!authInfo.authenticated) {
      this.router.navigate(["/login"]).then(b=>{});
      return false;
    }
    else{
      this.isactivated = 1;
    }
    return true;
  }

}
