import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
@Injectable({
  providedIn: 'root'
})

export class AppDataClass {
 apiUrl    =   'https://bewo-sbm.de/nordapi.php/';
  // apiUrl    =   'https://test.bewo-sbm.de/nordapi.php/'; ///  localStorage.getItem('apiurl') || 'https://bewo-sbm.de/nordapi.php/';
  apiGetter =   '?apprequest';


  constructor(public loadingCtrl: LoadingController) {}

  getApiUrl(){
    return this.apiUrl+this.apiGetter;
  }


  getSaveRepaUrl(){
    return this.apiUrl+'?reparatur';
  }



  loginUrl(){
    return  this.getApiUrl()+'&getapiaccess2=1';
  }

  getterUrl(){
    /// NUR DIE API URL - DAHINTER MIT & aufrufen
    return  this.apiUrl+this.apiGetter;
  }



  loginHeader(usermail,pass){
    return {
      'User':usermail,
      'Password':pass,
    } ;
  }

  userUrl(){
    return  this.getApiUrl()+'&getuser=1';
  }

  objektListUrl(){
    return  this.getApiUrl()+'&objlist';
  }

  sendDatasUrl(){
    return       this.getApiUrl(); ///this.apiUrl+'?abnahme_v2';
  }


  lastTabsUrl(obj_id){
    return  this.getApiUrl()+'&gettabs='+obj_id;
  }

  vorgangUrl(id){
    return  this.getApiUrl()+'&vorgang='+id;
  }

  appDatasUrl(){
    return  this.getApiUrl()+'&tabdatas=1';
  }

  appReparaturUrl(){
    return  this.getApiUrl()+'&reparatur=1';
  }


  showLoader(text) {
    this.loadingCtrl.create({
      message: text,
      duration: 400,
      spinner: 'dots',
      backdropDismiss: false,
      translucent: true,
      animated: true,
      cssClass: 'myLoader',
    }).then((res) => {
      res.present().then(r => {});
    });
  }

  closeLoader() {
    this.loadingCtrl.dismiss().then((res) => {

    }).catch((error) => {

    });
  }


  resetDatas(){
    /// objekte
    localStorage.removeItem('objekte');



  }



}
